<template>
    <div class="contaniner">
        <div class="list" v-loading="fetchingData">
            <template v-for="item in list">
                <logo-demand :key="item.id" :info="item" isEdit @refreash="getData"></logo-demand>
            </template>

            <div class="empty" v-if="empty && !fetchingData">
                <i class="el-icon-takeaway-box" style="margin-left: 28px"></i>
                <div class="empty-text" style="font-size: 30px">暂无数据</div>
            </div>
        </div>
        <div class="footer">
            <el-pagination
                @size-change="onSizeChange"
                @current-change="onCurrentChange"
                :current-page="page"
                :page-sizes="[10, 20, 30, 40, 50]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalElements"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import LogoDemand from '../../../components/list/LogoDemand.vue';
import pageableTable from '../../../mixins/pageableTable';
export default {
    components: { LogoDemand },
    mixins: [pageableTable],
    data() {
        return {
            url: '/logoDemand/all',
            list: []
        };
    },
    computed: {
        ...mapState(['userInfo'])
    },
    watch: {
        $route() {
            this.$nextTick(() => {
                this.page = 1;
                this.getData();
            });
        }
    },
    methods: {
        beforeGetData(query) {
            return {
                query: {
                    ...query,
                    userId: this.userInfo.id
                }
            };
        },
        setList(list) {
            this.list = list;
            // console.log(this.list);
        },
        addRow() {
            this.$router.push({
                path: '/logoDemandEdit',
                query: {
                    ...this.$route.query
                }
            });
        }
    }
};
</script>

<style lang="less" scoped>
.contaniner {
    .flex-col();
}
.list {
    flex-grow: 1;
}

.footer {
    flex-shrink: 0;
}
.empty {
    position: absolute;
    top: 70%;
    left: 53%;
    font-size: 60px;
    color: #ccc;
}
</style>
